import { mapGetters } from "vuex"

export default {
    computed: {
        ...mapGetters({
            background_url: 'company/background_url',
            primary_color : 'company/primary_color',
        }),
        computedBgStyle() {
            return {
                'background-image': `url(${this.background_url})`,
                'background-position': 'center',
                'background-size': 'cover',
                'background-repeat': 'no-repeat',
                'position': 'relative',
            }
        },
        computedLyStyle() {
            return {
                'position': 'absolute',
                'top': 0,
                'right': 0,
                'bottom': 0,
                'left': 0,
                'width': '100%',
                'height': '100%',
                'background-color': this.primary_color,
                'opacity': 0.7,
            }
        },
    },
}