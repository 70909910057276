<template>
    <div class="fill-height" :style="computedBgStyle">
        <div :style="computedLyStyle"></div>
        <v-container d-flex fill-height justify-center align-center>

            <v-card width="100%" max-width="374px" class="mx-auto px-7">
                <v-img v-if="logo_url" :src="logo_url" max-width="190" max-height="190" class="mx-auto mt-12 mb-6" contain/>

                <v-card-title class="font-weight-bold card-title">
                    {{ $t('views.login.title') }}
                </v-card-title>
                <v-card-subtitle class="mb-6 card-subtitle">
                    {{ $t('views.login.subtitle') }}
                </v-card-subtitle>

                <v-card-text>

                    <v-alert type="success" :value="logout && shouldShowAlerts" transition="fade-transition">
                        {{ $t('views.login.logout_success') }}
                    </v-alert>

                    <v-alert type="success" :value="passwordReset && shouldShowAlerts" transition="fade-transition">
                        {{ $t('views.login.passwordReset') }}
                    </v-alert>

                    <v-alert type="warning" :value="authNeeded && shouldShowAlerts" transition="fade-transition">
                        {{ $t('views.login.auth_needed') }}
                    </v-alert>

                    <v-alert type="error" :value="reAuth && shouldShowAlerts" transition="fade-transition">
                        {{ $t('views.login.reAuth') }}
                    </v-alert>

                    <v-alert type="error" :value="tokenExpired && shouldShowAlerts" transition="fade-transition">
                        {{ $t('views.login.tokenExpired') }}
                    </v-alert>

                    <router-view></router-view>

                </v-card-text>
            </v-card>

        </v-container>
    </div>
</template>

<script>
import { mapGetters }       from 'vuex'
import HasBackgroundMixin   from '@/mixins/HasBackgroundMixin'
import HasErrorHandlerMixin from '@/mixins/HasErrorHandlerMixin'

export default {
    name: 'AuthBase',
    props: {
        // Indica se o usuário acabou de fazer logout
        logout: Boolean,

        // Indica se o usuário foi redirecionado para fazer login
        authNeeded: Boolean,

        // Indica se o usuário foi redirecionado para fazer login
        reAuth: Boolean,

        // Indica se o token expirou
        tokenExpired: Boolean,

        // Indica se o usuário acabou de redefinir sua senha
        passwordReset: Boolean,
    },
    mixins: [ HasBackgroundMixin, HasErrorHandlerMixin ],
    data: vm => ({
        shouldShowAlerts  : true,
    }),
    mounted() {
        // Depois de 5s os alertas somem
        setTimeout(() => {
            this.shouldShowAlerts = false
        }, 5000)
    },
    computed: {
        ...mapGetters({
            logo_url: 'company/logo_url',
        }),
    },
}
</script>

<style scoped lang="scss">
.v-card:not(.v-sheet--tile):not(.v-card--shaped) {
    border-radius: 8px !important;
}

.card-title {
    font-size: 28px;
}
</style>